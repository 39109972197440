import { defineStore } from 'pinia'
export const useGuestStore = defineStore({
  id: 'guest',
  state: () => ({
    salutation: '',
    salutationError: false,
    firstname: '',
    firstnameError: false,
    lastname: '',
    lastnameError: false,
    email: '',
    emailError: false,
    telCountry: '',
    telNumber: '',
    telNumberValid: false,
    telNumberError: false,
    address: '',
    addressError: false,
    postalCode: '',
    postalCodeError: false,
    placename: '',
    placenameError: false,
    countryCode: '',
    businessBooking: false,
    companyName: '',
    companyNameError: false,
    csBookingType: 'Booking',
    csSalesPartner: '',
    csDoNotSendDocuments: false,
    discountCode: '',
    discountAmount: 0,
    guestCredits: 0,
    useGuestCredits: 0,
    guestId: ''
  }),
  actions: {
    setError(field: string, bool: boolean) {
      switch (field) {
        case 'salutation':
          this.salutationError = bool
          break
        case 'firstname':
          this.firstnameError = bool
          break
        case 'lastname':
          this.lastnameError = bool
          break
        case 'email':
          this.emailError = bool
          break
        case 'telNumber':
          this.telNumberError = bool
          break
        case 'address':
          this.addressError = bool
          break
        case 'postalCode':
          this.postalCodeError = bool
          break
        case 'placename':
          this.placenameError = bool
          break
        case 'companyName':
          this.companyNameError = bool
          break
      }
    }
  }
})
